import { apiKey, apiUrl } from "../settings";

import Layout from "../templates/layout";
import React from "react";
import { connect } from "react-redux";
import { environment } from "../environments/environment";
import { find } from "lodash";

class PageNotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //actions

  fetchCms() {
    return fetch(apiUrl, {
      method: "GET",
      headers: {
        "Api-Key": apiKey,
      },
    });
  }

  fetchWebsite(cms) {
    // Fast escape
    if (!cms || !cms.channels || !cms.channels.web || !cms.channels.web["nl-BE"]) {
      return [];
    }

    const websitesArray = cms.channels.web["nl-BE"].filter(item => item.templateName === "Websites");

    let websites = [];
    websitesArray.forEach(item => {
      const subItems = item.children.filter(subItem => subItem.templateName === "Website");
      websites = websites.concat(subItems);
    });
    return websites;
  }

  fetchNavigation(cms, website) {
    if (
      !cms ||
      !cms.channels ||
      !cms.channels.web ||
      !cms.channels.web["nl-BE"] ||
      !website ||
      !website.content ||
      !website.content.navigation
    ) {
      return [];
    }

    const websitesArray = cms.channels.web["nl-BE"].filter(item => item.templateName === "Websites");
    const websites = websitesArray[0];

    // Get websites navigation
    const navigation = [find(websites.children, { templateName: "Navigatie" })];

    // Get website navigation
    const websiteNavigation = website.content.navigation;
    if (!websiteNavigation || !navigation) {
      return undefined;
    }

    // Get header, footer and contact navigation
    const headerNavigation = websiteNavigation.headerNavigation;
    const footerNavigation = websiteNavigation.footerNavigation;
    const contactNavigation = websiteNavigation.contactNavigation;

    if (!headerNavigation || !footerNavigation || !contactNavigation) {
      return undefined;
    }

    // Get navigation children
    let headerNavigationChildren;
    let footerNavigationChildren;
    let contactNavigationChildren;

    for (var navigationItem of navigation) {
      if (!headerNavigationChildren) {
        headerNavigationChildren = this.findChildNode(navigationItem, headerNavigation.itemId);
      }
      if (!footerNavigationChildren) {
        footerNavigationChildren = this.findChildNode(navigationItem, footerNavigation.itemId);
      }
      if (!contactNavigationChildren) {
        contactNavigationChildren = this.findChildNode(navigationItem, contactNavigation.itemId);
      }

      if (headerNavigationChildren && footerNavigationChildren && contactNavigationChildren) {
        break;
      }
    }

    if (!headerNavigationChildren || !footerNavigationChildren || !contactNavigationChildren) {
      return undefined;
    }

    return {
      headerNavigation: {
        content: headerNavigation.content,
        items: headerNavigationChildren.children,
      },
      footerNavigation: {
        content: footerNavigation.content,
        items: footerNavigationChildren.children,
      },
      contactNavigation: {
        content: contactNavigation.content,
        items: contactNavigationChildren.children,
      },
    };
  }

  findChildNode(node, itemId) {
    if (node && node.children) {
      for (const child of node.children) {
        if (child.itemId === itemId) {
          return child;
        }

        const recursive = this.findChildNode(child, itemId);
        if (recursive) {
          return recursive;
        }
      }
    }
    return undefined;
  }

  async fetchData() {
    let { navigation } = this.state;

    try {
      this.setState({});

      const cmsResponse = await this.fetchCms();
      const cms = await cmsResponse.json();

      //website

      const websites = this.fetchWebsite(cms);
      for (const build of environment.builds) {
        const website = find(websites, { name: build.name });

        // Navigation
        if (!navigation || !navigation.length) {
          navigation = this.fetchNavigation(cms, website);
        }
      }

      this.setState({
        navigation: navigation,
      });
    } catch (e) {
      this.setState({});
    }
  }

  async componentDidMount() {
    await this.fetchData();
  }

  render() {
    const { navigation } = this.state;
    const theme = environment.builds[0].key;

    return (
      <Layout isTiny={true} showQsm={false} theme={theme} isTinted={true} navigation={navigation}>
        <div className="products">
          <div className="products__title">
            <h2>De boot gemist...</h2>
          </div>

          <div className="products__content">
            <div className="page-not-found">
              <p>
                Helaas, deze pagina bestaat (nog) niet. Is dit een fout? Blijft dit zich voordoen?{" "}
                <a
                  href="mailto:cruise@thalassa.be?subject=Pagina%20niet%20gevonden"
                  title="Contacteer onze reisexperten"
                  className="link"
                >
                  Contacteer onze reisexperten
                </a>{" "}
                en we kijken het na.
              </p>
              <a href="/" className="button" title="Terug naar de homepage">
                Terug naar de homepage
              </a>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const {
    app: { navigation },
  } = state;

  return { navigation };
}

export default connect(mapStateToProps)(PageNotFound);
